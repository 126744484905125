<template>
  <div>
    <a-table
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :customRow="setRow"
      @change="changePage"
      :pagination="pagination"
      :loading="loading"
      :columns="columns"
      :data-source="list"
      :scroll="{ x: 800 }"
      :rowKey="(record) => record.spliderId"
    >
      <div
          slot="image"
          slot-scope="row">
        <a-tooltip placement="right">
          <template #title>
            <div style="width: 400px;display: flex; justify-content: space-between; align-items: center">
              <img
                  @click="previewImg(row)"
                  :src='row.ImageInfoUrl[0]'
                  style="width: 100%;height: 100%;"
              />
              <img
                  @click="previewImg(row)"
                  :src='row.ImageInfoUrl[1]'
                  style="width: 100%;height: 100%;"
              />
            </div>
          </template>
          <img
              @click.stop="previewImg(row)"
              style="max-width: 100px; max-height: 100px"
              :src="row.ImageInfoUrl[0]"
          />
        </a-tooltip>
      </div>
      <div
        slot="setup"
        slot-scope="row"
        style="display: flex; flex-direction: column"
      >

        <div style="margin-top: 5px; display: flex;justify-content: center;align-items: center;">
          <a-popconfirm
            title="是否确认?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="confirm(row.spliderId)"
            :disabled="row.markType == 1 || row.markType == 2"
          >
            <a-button
              size="small"
              type="primary"
              style="margin-right: 5px"
              :disabled="row.markType == 1 || row.markType == 2"
            >推送标注</a-button>
          </a-popconfirm>

          <!-- 新需求拿掉 -->
          <!-- <a-button
            size="small"
            type="primary"
            style="margin-right: 5px"
            @click="pushClick(row)"
            :disabled="row.markType == 1 || row.markType == 2"
          >推送到底库</a-button> -->
          <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteItem(row.spliderId)"
          >
            <a-button
              size="small"
              type="danger"
            >删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
    <!--  -->
    <ModalPush ref="pushModal" @success="pushSuccess"></ModalPush>

  </div>
</template>

<script>
import ModalPush from "@/views/cmsPage/versionManage/pretreatmentMark/ModalPush.vue";
export default {
  props: ["list", "loading", "pagination"],
  components: {
    ModalPush,
  },
  data() {
    return {
      selectedRowKeys: [],
      columns: [
        { title: "id", dataIndex: "spliderId", width: 120 },
        { title: "版别名称", dataIndex: "versionName", width: 180 },
        { title: "币种名称", dataIndex: "coinName", width: 180 },
        { title: "图片", width: 180, scopedSlots: { customRender: "image" } },
        {
          title: "推荐程度",
          width: 180,
          customRender: (text, row, index) => {
            switch (row.featuredStatus) {
              case 0:
                return <span style="color: black">普通</span>;
              case 1:
                return <span style="color: darkcyan">精彩</span>;
              case 2:
                return <span style="color: orange">精选</span>;
              default:
                break;
            }
          },
        },
        {
          title: "推送状态",
          dataIndex: "markType",
          width: 180,
          customRender: (text, row, index) => {
            switch (row.markType) {
              case 0:
                return "未推送";
              case 1:
                return "已推送";
              default:
                return "未推送";
            }
          },
        },

        {
          title: "机器对版",
          dataIndex: "duibanStatus",
          width: 180,
          customRender: (text, row, index) => {
            switch (row.duibanStatus) {
              case 0:
                return "未对版";
              case 1:
                return "已对版";
              default:
                return "未对版";
            }
          },
        },

        {
          title: "操作",
          align: "center",
          // fixed: 'right',
          scopedSlots: { customRender: "setup" },
          width: 200,
        },
      ],
    };
  },
  watch: {
    loading() {
      this.selectedRowKeys = [];
      this.$emit("selectChange", []);
    },
  },
  methods: {
    /** 点击表格 */
    setRow (record) {
      return {
        on: {
          click: () => {
            let flag = false
            let keys = []
            keys = this.selectedRowKeys
            if(keys.indexOf(record.spliderId) >= 0) {
              flag=true
            }
            if(flag){
              keys.splice(keys.findIndex(item => item === record.spliderId), 1)
            } else {
              keys.push(record.spliderId)
            }
            this.$emit("selectChange", keys);
          }
        }
      }
    },
    // 推送成功
    pushSuccess(){
      this.$emit("success");
    },
    // 推送到样本库点击
    pushClick(row) {
      this.$refs["pushModal"].show(row);
    },
    onSelectChange(keys) {
      this.selectedRowKeys = keys;
      this.$emit("selectChange", keys);
    },
    // 推送同步
    async confirm(id) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/quanhaiVersionDataSplider/confirmationData",
        {
          params: { spliderIds: id + "" },
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
    // 删除
    async deleteItem(id) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/quanhaiVersionDataSplider/deleteById",
        {
          params: {
            ids: id + "",
          },
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.$emit("deleteItem", id);
    },
    // 切换分页
    changePage(page) {
      this.$emit("changePage", page);
    },
    // 预览封面
    previewImg(row) {
      const images = row.ImageInfoUrl.map((el) => {
        return {
          img_url: el,
        };
      });
      this.$previewImg({
        list: images,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-content {
  max-width: 600px;
}
</style>