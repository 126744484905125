var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          },
          customRow: _vm.setRow,
          pagination: _vm.pagination,
          loading: _vm.loading,
          columns: _vm.columns,
          "data-source": _vm.list,
          scroll: { x: 800 },
          rowKey: function(record) {
            return record.spliderId
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "image",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { placement: "right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "400px",
                                      display: "flex",
                                      "justify-content": "space-between",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: { src: row.ImageInfoUrl[0] },
                                      on: {
                                        click: function($event) {
                                          return _vm.previewImg(row)
                                        }
                                      }
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: { src: row.ImageInfoUrl[1] },
                                      on: {
                                        click: function($event) {
                                          return _vm.previewImg(row)
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "100px",
                          "max-height": "100px"
                        },
                        attrs: { src: row.ImageInfoUrl[0] },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.previewImg(row)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            }
          },
          {
            key: "setup",
            fn: function(row) {
              return _c(
                "div",
                {
                  staticStyle: { display: "flex", "flex-direction": "column" }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "5px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center"
                      }
                    },
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "是否确认?",
                            "ok-text": "Yes",
                            "cancel-text": "No",
                            disabled: row.markType == 1 || row.markType == 2
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.confirm(row.spliderId)
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-right": "5px" },
                              attrs: {
                                size: "small",
                                type: "primary",
                                disabled: row.markType == 1 || row.markType == 2
                              }
                            },
                            [_vm._v("推送标注")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.deleteItem(row.spliderId)
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            { attrs: { size: "small", type: "danger" } },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            }
          }
        ])
      }),
      _c("ModalPush", { ref: "pushModal", on: { success: _vm.pushSuccess } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }