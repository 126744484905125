<template>
  <div class="page">
    <div class="page-header">
      <div>
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @select="changeVersionlistSearch"
          :filter-option="untils.filterOption"
          @search="handleChangeSearchBigCoin"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 150px"
          v-model="params.coinName"
          placeholder="请选择大币种"
          :dropdownMenuStyle="{'max-height': '500px'}"
        >
          <a-select-option
            v-for="items of screenList"
            :key="items.coinName"
            :value="items.coinName"
          >{{ items.coinName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @select="selectVersionSearch"
          allowClear
          showSearch
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 200px"
          v-model="params.versionId"
          placeholder="请选择版别"
        >
          <a-select-option
            v-for="item of versionList"
            :key="item.versionId"
            :value="item.versionId"
          >{{ item | versionText }}</a-select-option>
        </a-select>
<!--    别名搜索    -->
        <a-input
            allowClear
            v-model="params.aliasName"
            size='small'
            style="width: 220px"
            placeholder='别名搜索'
        ></a-input>
        <!-- 标注状态 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          size="small"
          style="width: 120px; margin: 5px"
          v-model="params.markType"
          placeholder="推送状态"
        >
          <a-select-option :value="0">未推送</a-select-option>
          <a-select-option :value="1">标注已推送</a-select-option>
          <a-select-option :value="2">样本库已推送</a-select-option>
        </a-select>
        <!-- 推荐程度 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          size="small"
          style="width: 120px; margin: 5px"
          v-model="params.featuredStatus"
          placeholder="推荐程度"
        >
          <a-select-option :value="0">普通</a-select-option>
          <a-select-option :value="1">精彩/精选</a-select-option>
        </a-select>


        <!-- 对版状态 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          size="small"
          style="width: 120px; margin: 5px"
          v-model="params.duibanStatus"
          placeholder="机器对版"
        >
          <a-select-option :value="0">未对版</a-select-option>
          <a-select-option :value="1">已对版</a-select-option>
        </a-select>

        <a-button
          @click="search"
          size="small"
          style="margin-left: 10px"
          type="primary"
        >搜索</a-button>

        <a-popconfirm
          title="Are you sure confirm this item?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="batchSend()"
          :disabled="isDisabled"
        >
          <a-button
            :disabled="isDisabled"
            size="small"
            style="margin-left: 10px"
          >批量推送标注</a-button>
        </a-popconfirm>
        <a-popconfirm
          title="Are you sure confirm this item?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="batchDelete()"
          :disabled="isDisabled"
        >
          <a-button
            :disabled="isDisabled"
            size="small"
            type="danger"
            style="margin-left: 10px"
          >批量删除</a-button>
        </a-popconfirm>
      </div>
    </div>
    <div class="page-body">
      <PageTable
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @selectChange="onSelectChange"
        @changePage="changePage"
        @success="getList"
        @deleteItem="deleteItem"
        ref="table"
      ></PageTable>
    </div>
  </div>
</template>

<script>
import PageTable from "@/views/cmsPage/versionManage/pretreatmentMark/PageTable.vue";
import untils from "@/untils";
export default {
  data() {
    return {
      untils,
      selctedArrays: [],
      list: [],
      loading: false,
      detail: "",
      params: {
        pageNum: 1,
        pageSize: 20,
        aliasName: undefined
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      screenList: [],
      versionList: [],
    };
  },
  computed: {
    isDisabled() {
      return this.selctedArrays.length <= 0;
    },
  },
  filters: {
    versionText(item) {
      return `${item.dynastyName ? `(${item.dynastyName})` : '' }${item.versionName}[${item.aliasName || ""}](${item.size})`;
    },
  },
  components: {
    PageTable,
  },
  async mounted() {
    await this.getList();
    await this.handleChangeSearchBigCoin()
  },
  methods: {
    // 批量删除
    async batchDelete() {
      let spliderIds = this.selctedArrays
      let ids = spliderIds.join(",");
      const res = await this.axios(
        "/dq_admin/quanhaiVersionDataSplider/deleteById",
        {
          params: { ids: ids },
        }
      );
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      // spliderIds.forEach(el=>{
      //   deleteItem(el.spliderId)
      // })
      this.getList();
    },
    // 删除
    deleteItem(id) {
      const index = this.list.findIndex((el) => {
        return el.spliderId == id;
      });
      this.list.splice(index, 1);
    },
    // 批量推送
    async batchSend() {
      let ids = this.selctedArrays
      ids = ids.join(",");
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/quanhaiVersionDataSplider/confirmationData",
        {
          params: { spliderIds: ids },
        }
      );
      this.loading = false;
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.getList();
    },
    // 获取table选中数据
    onSelectChange(selectedArrays) {
      this.selctedArrays = selectedArrays
    },
    async handleChangeSearchBigCoin(inner) {
      const res = await this.axios(
        "/dq_admin/quanhaiVersionDataSplider/quanhaiCoinList",{
          params: {
            pageSize: 10,
            pageNum: 1,
            coinName: inner
          }
          }
      );
      if (res.status != 200) return;
      this.screenList = res.data;
    },
    // 选择大币种
    async changeVersionlistSearch(val) {
      const res = await this.axios(
          "/dq_admin/quanhaiVersionDataSplider/listSizeQuanHaiVersionData",{
            params: {
              coinName: val
            }
          }
      );
      if (res.status != 200) return;
      const coin = res.data.find((el) => {
        return el.coinName == val;
      });
      delete this.params.versionId;
      console.log(coin.versionList, 'list')
      this.versionList = coin.versionList
    },
    // 选择版别
    selectVersionSearch() {
      this.search();
    },
    // 分页
    changePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },

    // 数据列表
    async getList() {
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/quanhaiVersionDataSplider/getQuanhaiVersionDataSpliderList",
        {
          params: this.params,
        }
      );
      this.loading = false;
      if (res.status != 200) return;
      this.pagination.total = res.data.total;
      this.list = res.data.records;
    },
    // 点击搜索按钮
    search() {
      if (this.params.aliasName) {
        if (!this.params.coinName) return this.$message.warn('搜索别名必须先选择大币种')
      }
      this.pagination.current = 1;
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
.page-header {
  display: flex;
}
</style>